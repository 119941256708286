import React, { useState, useEffect, useMemo } from "react"
import { debounce } from "../../utilities/debounce";
import * as styles from './QuantitySelector.module.scss';
import { ButtonResult, ButtonType, PopupSize, popup, Loader } from "ui";
import cn from 'classnames';
import { RemanResults } from "../../types";

enum ButtonState {
    Normal,
    Loader,
    LoadedLabel,
    LoadedReman,
    RemanChosen,
    RemovingItem
}

const cancelDefault = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
}

function isNumeric(str: string | number) {
    if (typeof str != "string") return false;
    return !isNaN(str as unknown as number) && !isNaN(parseFloat(str));
}

const clamp = (v: number, min: number, max: number) => Math.min(max, Math.max(min, v));

const translations = (window as any).app.preloadState.translation;

export const showNotAuthorizedPopup = (ev: React.MouseEvent) => {
    ev.preventDefault();
    popup(
        translations["sparePartVariationButtonOptions.cannotBuy"],
        <div>
            {translations["sparePartVariationButtonOptions.insufficientRightsToBuy"]}<br /><br />
            <a href="https://myswecon.swecon.se/mitt-konto/hantera-anvandare/">{translations["sparePartVariationButtonOptions.contactMySweconAdministrator"]}</a>
        </div>,
        [
            { label: translations["sparePartVariationButtonOptions.close"], result: ButtonResult.Cancel, type: ButtonType.Primary }
        ],
        PopupSize.Small
    );
}

type QuantitySelectorProps = {
    step: number;
    min: number;
    max: number;
    initialValue: number;
    disabled?: boolean;
    onChange: (value: number) => void;
    onRemove: (doAfter: () => void) => void;
    onAddReman?: (value: number) => void;
    buttonLabel: string;
    buttonLabelReman?: string;
    buttonLabelRemanBase?: string;
    buttonLabelAdded: string;
    buttonLabelRemanAdded?: string;
    buttonLabelAddToCart?: string;
    forceMinValue: boolean;
    showLogin: boolean;
    showNotAuthorized: boolean;
    showRequestQuotationByEmail: boolean;
    showContactSupport: boolean;
    showBuyButton: boolean;
    showAddToCartButton?: boolean;
    showNotAvailableDisabled?: boolean;
    requestQuotationByEmail: string | null;
    contactSupportLink: string | null;
    className?: string;
    showRemanufacturePopUp?: () => Promise<RemanResults[string] | undefined>;
    buttonClassName?: string;
    hasRemanInCart?: boolean;
    hasRemanAvailable?: boolean;
    newDesign?: boolean;
    pdpDesign?: boolean;
}

export const QuantitySelector = ({
    step,
    min,
    max,
    initialValue,
    disabled,
    onChange,
    onRemove,
    onAddReman = () => { },
    buttonLabel,
    buttonLabelReman = "",
    buttonLabelRemanBase = "",
    buttonLabelAdded,
    buttonLabelRemanAdded = "",
    buttonLabelAddToCart = "",
    forceMinValue,
    showLogin,
    showNotAuthorized,
    showRequestQuotationByEmail,
    showContactSupport,
    showBuyButton,
    showAddToCartButton,
    showNotAvailableDisabled,
    requestQuotationByEmail,
    contactSupportLink,
    className = "",
    showRemanufacturePopUp = undefined,
    buttonClassName = "btn--primary",
    hasRemanInCart,
    hasRemanAvailable = false,
    newDesign = true,
    pdpDesign = false
}: QuantitySelectorProps) => {
    const [textValue, setTextValue] = useState(initialValue.toString());
    const [lastValidValue, setLastValidValue] = useState(initialValue);
    const [shouldShowButton, setShowButton] = useState(initialValue === 0);
    const maxTruncated = Math.trunc(max / step) * step;

    const loginLink = (window as any).app.preloadState.loginLink;
    const [infoMessage, setInfoMessage] = useState("");
    
    const updateValue = (text: string | number) => {
        setInfoMessage("");

        if (typeof text === 'number') {
            text = text.toString();
        }
        setTextValue(text);
        if (isNumeric(text)) {
            const num = +text;
            min = Math.max(min, 1);
            setLastValidValue(num);
            if (forceMinValue && num < min) {
                setLastValidValue(min);
                setTextValue(min.toString());
            }
        }
    };

    const debouncedOnChange = useMemo(() => debounce(onChange, 500), [onChange]);

    useEffect(() => {
        if (!disabled && checkForErrors(textValue, lastValidValue) === null && lastValidValue > 0) {
            debouncedOnChange(lastValidValue);
        }
    }, [lastValidValue, textValue]);

    const updateButton = (v: number) => {
        const shouldRemove = v === 0;
        if (shouldRemove) {
            debouncedOnChange.stop();
            onRemove(() => setShowButton(true));
        } else {
            setShowButton(false);
        }
    }

    const increment = () => {
        const intendedValue = Math.trunc((lastValidValue + step) / step) * step;
        const possibleValue = clamp(intendedValue, min, maxTruncated)
        updateValue(possibleValue);

        if (intendedValue > possibleValue)
            setInfoMessage(translations["quantitySelectorValidation.maxNumberOfItems"] ?? "");


    }
    const decrement = () => {
        const newValue = clamp(Math.trunc((lastValidValue - step) / step) * step, 0, max);
        updateValue(newValue);
        if (!forceMinValue) {
            updateButton(newValue);
        }
    }

    const add = async () => {

        const addSparePartToCart = (quantity?: number) => {
            setButtonState(ButtonState.Loader);
            (quantity == 0) ?
                setTimeout(() => setButtonState(ButtonState.RemovingItem), 1000)
                :
                setTimeout(() => setButtonState(ButtonState.LoadedLabel), 1000)

            setTimeout(() => setButtonState(ButtonState.Normal), 2000);
            updateValue(quantity ?? step);
            updateButton(quantity ?? step);
        }

        const addRemanItemToCart = (quantity: number) => {
            onAddReman(quantity);

            setButtonState(ButtonState.Loader);
            setTimeout(() => setButtonState(ButtonState.LoadedReman), 1000);
            setTimeout(() => setButtonState(ButtonState.RemanChosen), 2000);
        }

        if (!showRemanufacturePopUp) {
            addSparePartToCart();
        } else {
            const popupData = await showRemanufacturePopUp();
            if (!popupData) {
                //undefined, user canceled the pop up
                //do nothing
                return;
            }
            if (popupData.selectedReman) {
                addRemanItemToCart(popupData.remanQuantity);
            } else {
                addSparePartToCart(popupData.sparePartQuantity);
            }
        }
    };

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key == "Enter") {
            e.currentTarget.blur();
        }
    }

    const checkForErrors = (textValue: string, numberValue: number) => {
        if (numberValue.toString() !== textValue) {
            return translations["quantitySelectorValidation.invalidValue"];
        }
        if (numberValue === 0) {
            return translations["quantitySelectorValidation.productRemoved"];
        }
        if (numberValue < min) {
            return translations["quantitySelectorValidation.valueTooLow"];
        }
        if (numberValue > max) {
            return translations["quantitySelectorValidation.valueTooHigh"];
        }
        if (numberValue % step !== 0) {
            return translations["quantitySelectorValidation.cannotDivideUnit"];
        }
        return null;
    };

    const errorMessage = checkForErrors(textValue, lastValidValue);

    const checkButton = () => updateButton(lastValidValue);

    const [buttonState, setButtonState] = useState(hasRemanInCart ? ButtonState.RemanChosen : ButtonState.Normal);

    if (showLogin)
        return <a className={cn(styles.buyButton, "btn btn--outlined justify-content-center", styles.buttonStyledAnchor)} href={loginLink}>{translations["sparePartVariationButtonOptions.login"]}</a>

    if (showNotAuthorized)
        return <span className={cn(styles.buyButton, "btn btn--outlined  justify-content-center", styles.buttonStyledAnchor)}
            title={translations["sparePartVariationButtonOptions.insufficientRightsToBuy"]}
            onClick={showNotAuthorizedPopup}>{translations["sparePartVariationButtonOptions.moreInformation"]}</span>
    
    if (showRequestQuotationByEmail)
        return <a className={cn(styles.buyButton, "btn btn--outlined justify-content-center", styles.buttonStyledAnchor)} href={requestQuotationByEmail ?? ""}>{translations["sparePartVariationButtonOptions.requestForQuotation"]}</a>

    if (showNotAvailableDisabled)
        return <a className={cn(styles.buyButton, "btn btn--primary justify-content-center", styles.disabled, styles.buttonStyledAnchor)}>{translations["sparePartVariationButtonOptions.notAvailable"]}</a>

    if (showContactSupport)
        return <a className={cn(styles.buyButton, "btn btn--outlined justify-content-center", styles.buttonStyledAnchor)} href={contactSupportLink ?? ""}>{translations["sparePartVariationButtonOptions.contactSupport"]}</a>

    if (!showBuyButton && !showAddToCartButton)
        return <span></span>;

    const MyBuyButton = () => {
        switch (buttonState) {
            case ButtonState.Loader:
                return <button className={cn(styles.buyButton, "btn firstLetterCapital", buttonClassName, styles.buttonLabelLoadingState)}>
                    <Loader/>
                </button>;
            case ButtonState.LoadedLabel:
                return <button className={cn(styles.buyButton, "btn", buttonClassName, styles.buttonLabelAddedState)}>
                   <span className={styles.buttonLabelAddedStateIcon}></span><span className={cn("firstLetterCapital", styles.buttonLabelAddedStateLbl)}>{buttonLabelAdded}</span>
                </button>;
            case ButtonState.LoadedReman:
                return <button className={cn(styles.buyButton, "btn", buttonClassName, styles.buttonLabelAddedState)}>
                    <span className={styles.buttonLabelAddedStateIcon}></span><span className={cn("firstLetterCapital", styles.buttonLabelAddedStateLbl)}>{buttonLabelRemanAdded}</span>
                </button>;
            case ButtonState.RemanChosen:
                return <button className={cn(styles.buyButton, "btn firstLetterCapital", buttonClassName)} onClick={add}>
                    {buttonLabelReman}
                </button>;
            case ButtonState.RemovingItem:
                return <button className={cn(styles.buyButton, "btn firstLetterCapital", buttonClassName)}>
                    {translations["common.itemRemoved"]}
                </button>;
            default:
                return <button className={cn(styles.buyButton, "btn firstLetterCapital", buttonClassName)} onClick={add}>
                    {hasRemanAvailable ? buttonLabelRemanBase : (showAddToCartButton ? buttonLabelAddToCart : buttonLabel) }
                </button>;
        };
    };

    return (
        <div onClick={cancelDefault} className={cn(className, newDesign && styles.newDesign, pdpDesign && styles.pdpDesign)}>
            {shouldShowButton || buttonState > 0
                ? <MyBuyButton/>
                : <div className={styles.numberInput}>
                    <div className={styles.input}>
                        <input disabled={disabled} onKeyUp={onEnter} type="number" value={textValue} onBlur={checkButton} onInput={(e) => updateValue(e.currentTarget.value)}></input>
                        <div className={styles.note}>{translations["sparePartVariationButtonOptions.countInCart"]}</div>
                        <div className={styles.inputChevrons}>
                            <div className={cn(styles.chevronUp, disabled ? styles.disabled : "")} onClick={increment}>
                                <div>&#x2039;</div>
                            </div>
                            <div className={cn(styles.chevronDown, disabled ? styles.disabled : "")} onClick={decrement}>
                                <div>&#x2039;</div>
                            </div>
                        </div>
                    </div>
                    {errorMessage !== null && (
                        <div className={styles.error}>{errorMessage}</div>
                    )}
                    {errorMessage == null && infoMessage?.length > 0 && (
                        <div className={styles.error}>{infoMessage}</div>
                    )}
                </div>
            }
        </div>
    );
}